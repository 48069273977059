import React, { useState } from "react";
import factions from "../assets/data/factions";
import faction__shape from "../assets/img/factions/header-shape.png";
import section_shapes from "../assets/img/factions/section-shapes.png";
import SectionHeader from "./SectionHeader";
const Factions = ({ bgImage }) => {
	const [index, setIndex] = useState(2);
	const [anime, setAnime] = useState(false);
	const { circle_img, glow_img, main_img, title, subtitle, text } =
		factions[index];

	const handleClick = (e) => {
		setIndex(e);
		setAnime(true);
	};

	const handleOver = () => {
		setAnime(false);
	};

	return (
		<section
			className="factions-section overflow-hidden"
			style={{
				background: `url(${bgImage}) #10182c no-repeat center center /cover`,
			}}
			id="fractions"
		>
			<div
				className="faction-shape"
				style={{
					background: `url(${faction__shape}) no-repeat center bottom / cover`,
				}}
			></div>
			<div className="container">
				<SectionHeader noShape="no-shape" title="the factions" />
				<div className="row justify-content-center">
					<div className="col-lg-10">
						<ul className="faction-nav pt-4">
							{factions &&
								factions.map(({ nav_img }, i) => (
									<li
										key={i}
										onClick={() => handleClick(i)}
										onMouseOver={() => handleOver()}
										className={i === index ? "active" : ""}
									>
										<img src={nav_img} alt="" />
									</li>
								))}
						</ul>
					</div>
				</div>
				<div className={`factions-tab-content ${anime && "anime"}`}>
					<div className="sub-wrap">
						<ul>
							{Object.values(circle_img).map(({ rounded_img }, i) => (
								<li key={i}>
									<img src={rounded_img} alt="" />
								</li>
							))}
						</ul>
					</div>
					<div className="main-wrap">
						<div className="thumb">
							<img src={glow_img} alt="" className="glow-image" />
							<img src={main_img} alt="" className="main-image" />
						</div>
						<div
							className="content"
							style={{
								background: `url(${section_shapes})  #00000025 no-repeat center center`,
							}}
						>
							<div className="cont">
								<h2 className="title">{title}</h2>
								<h5 className="subtitle">{subtitle}</h5>
								<p>{text}</p>
								<span>--------</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Factions;
