import img1 from "../img/collection/1.png";
import img10 from "../img/collection/10.png";
import img11 from "../img/collection/11.png";
import img12 from "../img/collection/12.png";
import img13 from "../img/collection/13.png";
import img14 from "../img/collection/14.png";
import img15 from "../img/collection/15.png";
import img16 from "../img/collection/16.png";
import img17 from "../img/collection/17.png";
import img18 from "../img/collection/18.png";
import img19 from "../img/collection/19.png";
import img2 from "../img/collection/2.png";
import img20 from "../img/collection/20.png";
import img21 from "../img/collection/21.png";
import img22 from "../img/collection/22.png";
import img23 from "../img/collection/23.png";
import img24 from "../img/collection/24.png";
import img25 from "../img/collection/25.png";
import img3 from "../img/collection/3.png";
import img4 from "../img/collection/4.png";
import img5 from "../img/collection/5.png";
import img6 from "../img/collection/6.png";
import img7 from "../img/collection/7.png";
import img8 from "../img/collection/8.png";
import img9 from "../img/collection/9.png";

const data = {
	menu: [
		{
			name: "Haku",
			link: "#",
		},
		{
			name: "Gameplay",
			link: "#game",
		},
		{
			name: "Whitepaper",
			link: "https://drive.google.com/u/0/uc?id=1l6lQGiMLNGZmDsVPMtKUlCQMP3d26x6C&export=download",
			target: "_blank",
		},
		{
			name: "Fractions",
			link: "#fractions",
		},
		{
			name: "Roadmap",
			link: "#roadmap",
		},
		{
			name: "team",
			link: "#team",
		},
		{
			name: "Partners",
			link: "#partners",
		},
	],
	nft__collections: [
		{
			img: img2,
		},
		{
			img: img3,
		},
		{
			img: img4,
		},
		{
			img: img5,
		},
		{
			img: img6,
		},
		{
			img: img7,
		},
		{
			img: img9,
		},
		{
			img: img10,
		},
		{
			img: img1,
		},
		{
			img: img8,
		},
		{
			img: img16,
		},
		{
			img: img23,
		},
		{
			img: img11,
		},
		{
			img: img12,
		},
		{
			img: img13,
		},
		{
			img: img14,
		},
		{
			img: img15,
		},
		{
			img: img17,
		},
		{
			img: img18,
		},
		{
			img: img19,
		},
		{
			img: img20,
		},
		{
			img: img21,
		},
		{
			img: img22,
		},
		{
			img: img24,
		},
		{
			img: img25,
		},
	],
};
export default data;
