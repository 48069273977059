import React, { useRef } from "react";
import Slider from "react-slick";
import game_play from "../assets/data/game-play";
import background_image from "../assets/img/game-play-bg.png";
import next_img from "../assets/img/next.png";
import prev_img from "../assets/img/prev.png";
import SectionHeader from "./SectionHeader";

const GamePlay = () => {
	const ref = useRef();
	const settings = {
		dots: false,
		arrows: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		fade: true,
		slidesToScroll: 1,
		pauseOnHover: false,
		pauseOnFocus: true,
	};

	const next = () => {
		ref.current.slickNext();
	};
	const previous = () => {
		ref.current.slickPrev();
	};
	return (
		<section
			className="gameplay-section"
			style={{
				background: `url(${background_image}) no-repeat center center / cover`,
			}}
			id="game"
		>
			<div className="container">
				<SectionHeader className="pb-lg-5" title="Game Play" />
				<div className="gameplay-slider pb-xl-5">
					<Slider {...settings} ref={ref}>
						{game_play &&
							game_play.map(({ img, text }, i) => (
								<div className="slide-item" key={i}>
									<div className="img-container d-flex align-items-center position-relative">
										<span className="prev-btn" onClick={previous}>
											<img src={prev_img} alt="" />
										</span>
										<div className="mx-3 flex-grow-1">
											<img src={img} alt="" />
										</div>
										<span className="next-btn" onClick={next}>
											<img src={next_img} alt="" />
										</span>
									</div>
									<h3 className="title">{text}</h3>
								</div>
							))}
					</Slider>
				</div>
				<div className="mt-5 text-center mb-4 join-txt">
					<a
						href="https://foxtopiagame.com"
						target="_blank"
						className="join--use-on"
						rel="noreferrer"
					>
						Join us on our online channels to stay up-to-date with the
						Foxtopia community
					</a>
					<div>
						<a
							href="http://discord.gg/foxtopia"
							target="_blank"
							className="discord"
							rel="noreferrer"
						>
							<span>Discord</span>
						</a>
					</div>
				</div>
			</div>
		</section>
	);
};

export default GamePlay;
