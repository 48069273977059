import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.scss";
import section__bg from "./assets/img/shadow-shapes.png";
import Banner from "./components/Banner";
import Factions from "./components/Factions";
import Footer from "./components/Footer";
import GamePlay from "./components/GamePlay";
import Header from "./components/Header";
import NftCollection from "./components/NftCollection";
import RoadMap from "./components/RoadMap";
import Team from "./components/Team";
import VideoSection from "./components/VideoSection";
function App() {useEffect(() => {
	AOS.init({
		easing: "ease-out-cubic",
		once: true,
		offset: 50,
	});
}, []);
	return (
		<>
			<Header />
			<Banner />
			<VideoSection />
			<Factions bgImage={section__bg} />
			<GamePlay />
			<NftCollection bgImage={section__bg} />
			<RoadMap />
			<Team />
			<Footer />
		</>
	);
}

export default App;
