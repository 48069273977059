import React from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { GoPrimitiveDot } from "react-icons/go";
import roadmap from "../assets/data/roadmap.js";
import item_bg from "../assets/img/roadmap-shape-2.png";
import bg_img from "../assets/img/roadmap-shape.png";
import SectionHeader from "./SectionHeader";

const RoadMap = () => {
	return (
		<section
			className="roadmap-section"
			style={{
				background: `url(${bg_img}) no-repeat center center / cover`,
			}}
			id="roadmap"
		>
			<div className="container">
				<SectionHeader className="pb-lg-5" title="Roadmap" />
				<div className="py-4"></div>
				<div className="roadmap-wrapper">
					<div className="row g-4">
						{roadmap &&
							roadmap.map(({ title, sup, info }, i) => (
								<div className="col-sm-6 col-xl-3" key={i}>
									<div
										className="roadmap-item"
										style={{
											background: `url(${item_bg}) no-repeat center center / 100% 100%`,
										}}
									>
										<h3 className="title">
											{title}
											{sup && <small>{sup}</small>}
										</h3>
										<ul>
											{info.map(({ subtitle, status }, j) => (
												<li key={j}>
													{status === true && (
														<BsCheckCircleFill />
													)}
													{status === null && (
														<small>
															<GoPrimitiveDot />
														</small>
													)}
													{subtitle}
												</li>
											))}
										</ul>
									</div>
								</div>
							))}
					</div>
				</div>
			</div>
		</section>
	);
};

export default RoadMap;
