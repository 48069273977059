import React from "react";
// import Countdown from "react-countdown";
import countLogo from "../assets/img/count-logo.png";
import haku__logo from "../assets/img/haku-logo.png";
// const renderer = ({ days, hours, minutes, seconds, completed }) => {
// 	if (completed) {
// 		return <h2>Expired Coundown</h2>;
// 	} else {
// 		return (
// 			<div className="d-flex justify-content-center">
// 				<span className="countdown">
// 					<span className="item">{days}d</span>
// 					<span className="item">{hours}h</span>
// 					<span className="item">{minutes}m</span>
// 					<span className="item">{seconds}s</span>
// 				</span>
// 			</div>
// 		);
// 	}
// };
const CountdownSection = () => {
	return (
		<section className="countdown-section pt-5">
			<div className="container">
				<div className="section-title">
					<img src={haku__logo} alt="" className="haku-logo" />
					<a
						href="https://forms.gle/bTsexgcXAbwavNAm7"
						class="discord mt-0 mb-4"
						target="_blank"
						rel="noreferrer"
					>
						<span>Get whitelisted</span>
					</a>
					<h2>Launching with </h2>
					<div className="count-logo">
						<img src={countLogo} alt="" />
					</div>
					<h2>in September 2022</h2>
				</div>
				{/* <Countdown
					date={new Date("September 25, 2022 23:15:20")}
					renderer={renderer}
				/> */}
			</div>
		</section>
	);
};

export default CountdownSection;
