import React, { useRef } from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import Slider from "react-slick";
import data from "../assets/data/data";
import CountdownSection from "./CountdownSection";
import SectionHeader from "./SectionHeader";
const NftCollection = ({ bgImage }) => {
	const ref = useRef();
	const next = () => {
		ref.current.slickNext();
	};
	const previous = () => {
		ref.current.slickPrev();
	};
	const settings = {
		dots: false,
		nav: false,
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 4,
		autoplay: true,
		speed: 3000,
		autoplaySpeed: 3000,
		cssEase: "linear",
		pauseOnHover: true,
		responsive: [
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				},
			},
			{
				breakpoint: 575,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
		],
	};
	return (
		<section
			className="nft-collection section-padding overflow-hidden"
			style={{
				background: `url(${bgImage}) #10182c no-repeat center center /cover`,
			}}
			id="nft"
		>
			<SectionHeader className="mb-3" title="HAKU NFT COLLECTION" />
			<div className="nft-container bg--black-25">
				<div className="nft-slide-wrapper">
					<div className="nav-btn prev" onClick={previous}>
						<BsChevronCompactLeft />
					</div>
					<Slider {...settings} ref={ref}>
						{data.nft__collections &&
							data.nft__collections.map(({ img }, i) => (
								<div className="nft-slide-item" key={i}>
									<img
										className="nft-slide-img"
										key={i}
										src={img}
										alt=""
									/>
								</div>
							))}
					</Slider>
					<div className="nav-btn next" onClick={next}>
						<BsChevronCompactRight />
					</div>
				</div>
				{/* <div className="row justify-content-evenly">
						<div className="anime-box col-md-3 col-6 col-sm-5">
							<div className="anime-wrap">
								{data.nft__collections &&
									data.nft__collections
										.map(({ img }, i) => (
											<div className="anime-card" key={i}>
												<img
													className="anime-item"
													key={i}
													src={img}
													alt=""
												/>
											</div>
										))
										.slice(0, 6)}
							</div>
						</div>
						<div className="anime-box col-md-3 col-6 col-sm-5">
							<div className="anime-wrap">
								{data.nft__collections &&
									data.nft__collections
										.map(({ img }, i) => (
											<div className="anime-card" key={i}>
												<img
													className=" anime-item"
													src={img}
													alt=""
												/>
											</div>
										))
										.slice(6, 12)}
							</div>
						</div>
						<div className="anime-box col-md-3 col-6 col-sm-5">
							<div className="anime-wrap">
								{data.nft__collections &&
									data.nft__collections
										.map(({ img }, i) => (
											<div className="anime-card" key={i}>
												<img
													className=" anime-item"
													src={img}
													alt=""
												/>
											</div>
										))
										.slice(13, 19)}
							</div>
						</div>
						<div className="anime-box col-md-3 col-6 col-sm-5">
							<div className="anime-wrap">
								{data.nft__collections &&
									data.nft__collections
										.map(({ img }, i) => (
											<div className="anime-card" key={i}>
												<img
													className=" anime-item"
													src={img}
													alt=""
												/>
											</div>
										))
										.slice(19, 26)}
							</div>
						</div>
					</div> */}
			</div>
			<div className="container">
				<CountdownSection />
			</div>
		</section>
	);
};

export default NftCollection;
