import React from "react";
import myVideo from "../assets/video/MtView(Loop).mp4";
import SectionHeader from "./SectionHeader";

const VideoSection = () => {
	return (
		<>
			<section className="video-section" id="story">
				<video className="mt-video" autoPlay loop muted>
					<source src={myVideo} type="video/mp4" />
				</video>
				<div className="container position-relative">
					<SectionHeader title="Our Game" />
				</div>
				<div className="container-fluid pe-xl-5 mt-auto position-relative">
					<div className="video-txt me-xxl-5">
						<p>
							Immerse yourself in fun-filled fantasy gameplay with PvP
							colosseum and PvE multiplayer systems. Upgrade and breed
							your heroes to create super-battle champions! Chance upon
							unique drops in-game to be convertible to rare NFTs.
						</p>
						<p>
							Embark on missions to discover the evil force behind the
							darkness that now looms over Foxtopia. Only the strongest
							will remain. Fates will be sealed and the weak will
							crumble. Your path, your choice. Join forces as a fox with
							your companion and rise above all!
						</p>
						<p>
							Strategize and use your NFTs for boosts in-game, with each
							faction having their own enhanced strengths, skills and
							abilities built into their DNA. Learn and master the traits
							of more than one class or faction, and unlock an
							unimaginable force of nature!
						</p>
					</div>
				</div>
			</section>
		</>
	);
};

export default VideoSection;
