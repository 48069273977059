import React, { useState } from "react";
import team from "../assets/data/team";
import effects from "../assets/img/effects-shape.png";
import team_header_shape from "../assets/img/team-header-shape.png";
import bg_img from "../assets/img/team-shape.png";
const Team = () => {
	const [index, setIndex] = useState(0);

	const handleClick = (e) => {
		setIndex(e);
	};
	console.log();

	return (
		<section className="team-section overflow-hidden" id="team">
			<div
				className="team-shape-img-2"
				style={{
					background: `url(${bg_img}) no-repeat center center / contain`,
				}}
			></div>
			<div className="team-shape-img">
				<img src={team_header_shape} alt="" />
			</div>
			<div className="container-fluid">
				<ul className="tab-menu mb-lg-5 pb-lg-5">
					<li
						className={index === 0 ? "active" : ""}
						onClick={() => handleClick(0)}
					>
						Core Team
						<span className="effects">
							<img src={effects} alt="" />
						</span>
					</li>
					<li
						className={index === 1 ? "active" : ""}
						onClick={() => handleClick(1)}
					>
						Dev Team
						<span className="effects">
							<img src={effects} alt="" />
						</span>
					</li>
				</ul>
				<div className="pt-lg-5 pb-lg-4"></div>
				<div className="team-tab-content">
					{index === 0 ? (
						<div className="row g-4 justify-content-center show">
							{team[index].team__data.map(
								({ img, designation, name, info }, i) => (
									<div className="col-sm-6 col-md-3" key={i}>
										<div className="team__item">
											<div className="team__item-img">
												<img src={img} alt={name} />
											</div>
											<div className="team__item-content">
												<h3 className="name">{name}</h3>
												<span className="designation">
													{designation}
												</span>
												{info && (
													<span className="info">{info}</span>
												)}
											</div>
										</div>
									</div>
								)
							)}
						</div>
					) : (
						<>
							<div className="row gy-4 gy-md-5 justify-content-center active">
								{team[index].team__data
									.map(({ img, designation, name, info }, i) => (
										<div className="col-sm-6 col-md-3" key={i}>
											<div className="team__item">
												<div className="team__item-img">
													<img src={img} alt={name} />
												</div>
												<div className="team__item-content">
													<h3 className="name">{name}</h3>
													<span className="designation">
														{designation}
													</span>
													{info && (
														<span className="info">{info}</span>
													)}
												</div>
											</div>
										</div>
									))
									.slice(0, 3)}
							</div>
							<div className="row gy-4 gy-md-5 justify-content-center mt-0 active">
								{team[index].team__data
									.map(({ img, designation, name, info }, i) => (
										<div className="col-sm-6 col-md-3" key={i}>
											<div className="team__item">
												<div className="team__item-img">
													<img src={img} alt={name} />
												</div>
												<div className="team__item-content">
													<h3 className="name">{name}</h3>
													<span className="designation">
														{designation}
													</span>
													{info && (
														<span className="info">{info}</span>
													)}
												</div>
											</div>
										</div>
									))
									.slice(3, 5)}
							</div>
							<div className="row gy-4 gy-md-5 justify-content-center mt-0 active">
								{team[index].team__data
									.map(({ img, designation, name, info }, i) => (
										<div className="col-sm-6 col-md-3" key={i}>
											<div className="team__item">
												<div className="team__item-img">
													<img src={img} alt={name} />
												</div>
												<div className="team__item-content">
													<h3 className="name">{name}</h3>
													<span className="designation">
														{designation}
													</span>
													{info && (
														<span className="info">{info}</span>
													)}
												</div>
											</div>
										</div>
									))
									.slice(5, 9)}
							</div>
						</>
					)}
				</div>
			</div>
		</section>
	);
};

export default Team;
