import img1 from "../img/team/1.png";
import img2 from "../img/team/2.png";
import img3 from "../img/team/3.png";
import img4 from "../img/team/4.png";
import img5 from "../img/team/5.png";
import img6 from "../img/team/6.png";
import img7 from "../img/team/7.png";
import img8 from "../img/team/8.png";
import img9 from "../img/team/9.png";
import core1 from "../img/team/core1.png";
import core2 from "../img/team/core2.png";
import core3 from "../img/team/core3.png";
import core4 from "../img/team/core4.png";
const team = [
	{
		team__data: [
			{
				img: core1,
				name: "Choco Lord",
				designation: "Founder",
				info: "Experienced financial guru, crypto entrepreneur and an avid gamer.",
			},
			{
				img: core2,
				name: "FoxLikeAl",
				designation: "Project Lead",
				info: "Crypto enthusiast and corporate banker of multi million dollar portfolios.",
			},
			{
				img: core3,
				name: "SpaceFox",
				designation: "Creative Lead",
				info: "Design whizz, marketing and sales director with years of experience locally and internationally.",
			},
			{
				img: core4,
				name: "Mazz",
				designation: "Community Lead",
				info: "Music and art enthusiast, creative content writer and mum to all.",
			},
		],
	},
	{
		team__data: [
			{
				img: img1,
				name: "AregularFox",
				designation: "Character artist",
			},
			{
				img: img2,
				name: " WhatTheFox",
				designation: "Concept artist",
			},
			{
				img: img3,
				name: " CatalinS",
				designation: "Community Manager",
			},
			{
				img: img4,
				name: " Alex Dragon",
				designation: "Blockchain Developer",
			},
			{
				img: img5,
				name: "Big Knight",
				designation: "Web Developer",
			},
			{
				img: img6,
				name: "Zee",
				designation: "Game Developer",
			},
			{
				img: img7,
				name: "Wind",
				designation: "Game Developer",
			},
			{
				img: img8,
				name: " Ngu",
				designation: "3d Animator",
			},
			{
				img: img9,
				name: "Hue",
				designation: "3d Animator",
			},
		],
	},
];
export default team;
