import main_img1 from "../img/factions/1.png";
import main_img2 from "../img/factions/2.png";
import main_img3 from "../img/factions/3.png";
import main_img4 from "../img/factions/4.png";
import main_img5 from "../img/factions/5.png";
import question_img from "../img/factions/question.png";
import rounded_img1 from "../img/factions/rounded-1.png";
import rounded_img2 from "../img/factions/rounded-2.png";
import rounded_img3 from "../img/factions/rounded-3.png";
import rounded_img4 from "../img/factions/rounded-4.png";
import rounded_img5 from "../img/factions/rounded-5.png";
import glow_img1 from "../img/factions/shadow-1.png";
import glow_img2 from "../img/factions/shadow-2.png";
import glow_img3 from "../img/factions/shadow-3.png";
import glow_img4 from "../img/factions/shadow-4.png";
import glow_img5 from "../img/factions/shadow-5.png";
import nav_img1 from "../img/nav-menu/1.png";
import nav_img2 from "../img/nav-menu/2.png";
import nav_img3 from "../img/nav-menu/3.png";
import nav_img4 from "../img/nav-menu/4.png";
import nav_img5 from "../img/nav-menu/5.png";
const factions = [
	{
		nav_img: nav_img1,
		glow_img: glow_img1,
		main_img: main_img1,
		circle_img: [
			{
				rounded_img: rounded_img1,
			},
			{
				rounded_img: question_img,
			},
			{
				rounded_img: question_img,
			},
		],
		title: "WINOA: ",
		subtitle: '"By my hand, you shall fall!"',
		text: "Known for their skillful hands, their grace and dexterity make them clerics with profound knowledge and wisdom. Possessing the ability to summon the powers of the sea and sky, their enemies are silenced by blizzards and raging storms.  Great is their power, for they choose between giving life or death.",
	},
	{
		nav_img: nav_img2,
		glow_img: glow_img2,
		main_img: main_img2,
		circle_img: [
			{
				rounded_img: rounded_img2,
			},
			{
				rounded_img: question_img,
			},
			{
				rounded_img: question_img,
			},
		],
		title: "FLARIUS:",
		subtitle: '"Be gone you fools! NOTHING will stand in my way!" ',
		text: 'Upholding the utmost loyalty and compassion, these knights protect their home with their lives. Masters of the expression "death from above",  they have the power to unleash hell to keep their enemies far away. ',
	},
	{
		nav_img: nav_img3,
		glow_img: glow_img3,
		main_img: main_img3,
		circle_img: [
			{
				rounded_img: rounded_img3,
			},
			{
				rounded_img: question_img,
			},
			{
				rounded_img: question_img,
			},
		],
		title: "Elexaus:",
		subtitle: '"I will lay down my life for my people!" ',
		text: "The rowdiest of them all, members of this faction have devoted themselves to gruelling years of training their bodies to withstand all forms of physical and elemental blows.",
	},
	{
		nav_img: nav_img4,
		glow_img: glow_img4,
		main_img: main_img4,
		circle_img: [
			{
				rounded_img: rounded_img4,
			},
			{
				rounded_img: question_img,
			},
			{
				rounded_img: question_img,
			},
		],
		title: "ARANI:",
		subtitle: '"Now you see me, now you don\'t."',
		text: "Quick as lightning with stealth like no other, they have perfected the art of lurking silently in the shadows and swiftly attacking from one location to another. Although not as powerful in close combat, the Arani are the undefeated masters of the shuriken and kyudo.",
	},
	{
		nav_img: nav_img5,
		glow_img: glow_img5,
		main_img: main_img5,
		circle_img: [
			{
				rounded_img: rounded_img5,
			},
			{
				rounded_img: question_img,
			},
			{
				rounded_img: question_img,
			},
		],
		title: "SATULONIA:",
		subtitle: '"You are just another pawn."',
		text: "The brains behind Foxtopia, they are crucial in maintaining balance within the ecosystem. Persistent and hungry for knowledge, these highly intelligent individuals can control your mind. With the ability to move objects through space, they are a silent yet deadly force of nature.",
	},
];
export default factions;
