const roadmap = [
	{
		title: "Phase 1",
		info: [
			{
				subtitle: "Assemble Team",
				status: true,
			},
			{
				subtitle: "Website V1.0",
				status: true,
			},
			{
				subtitle: "Discord Community   Building",
				status: true,
			},
			{
				subtitle: "Animated Trailer V1.0",
				status: true,
			},
			{
				subtitle: "Genesis (SOLD OUT)",
				status: true,
			},
		],
	},
	{
		title: "Phase 2",
		sup: "(1H 2022)",
		info: [
			{
				subtitle: "Main collection (SOLD OUT)",
				status: true,
			},
			{
				subtitle: "Whitepaper V1.0",
				status: true,
			},
			{
				subtitle: " Public Mint on Magic Eden ",
				status: true,
			},
			{
				subtitle: "Listing on Marketplaces",
				status: true,
			},
			{
				subtitle: "Kitsune DAO Governance",
				status: true,
			},
			{
				subtitle: "Game Team Expansion",
				status: true,
			},
		],
	},
	{
		title: "Phase 3",
		sup: "(2H 2022)",
		info: [
			{
				subtitle: "NFT Staking",
				status: true,
			},
			{
				subtitle: "Website 2.0",
				status: true,
			},
			{
				subtitle: "Cinematic Trailer",
				status: true,
			},
			{
				subtitle: "Launch of Haku Companions",
				status: null,
			},
			{
				subtitle: "Game demo V1.0 released",
				status: null,
			},
			{
				subtitle: "NFT breeding",
				status: null,
			},
		],
	},
	{
		title: "Phase 3",
		sup: "(2H 2022)",
		info: [
			{
				subtitle: `Official launch of "The Defense of Foxtopia"`,
				status: null,
			},
			{
				subtitle: "Expansion of Foxtopia Fantasy World Map ",
				status: null,
			},
			{
				subtitle: "Unlocking of Hidden Foxes Lair ",
				status: null,
			},
			{
				subtitle: "Foxtopia Metaverse Implementation",
				status: null,
			},
		],
	},
];

export default roadmap;
