import React from "react";

const SectionHeader = ({ noShape, className, title }) => {
	return (
		<div className={`section-header ${noShape} ${className}`}>
			<h2 className="title">{title}</h2>
		</div>
	);
};

export default SectionHeader;
