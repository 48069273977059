import React, { useEffect, useRef, useState } from "react";
import data from "../assets/data/data";
import close_icon from "../assets/img/close_icon.png";
import logo from "../assets/img/logo.png";
import toggler from "../assets/img/toggler.png";
const Header = () => {
	const ref = useRef();

	useEffect(() => {
		const headerFunc = window.addEventListener("scroll", () => {
			if (window.scrollY > 100) {
				ref.current.classList.add("active");
			} else {
				ref.current.classList.remove("active");
			}
		});
		return headerFunc;
	}, []);

	const [toggleMenu, setToggleMenu] = useState(false);
	return (
		<header className="header-section" ref={ref}>
			<div className="container-fluid">
				<div className="header-wrapper">
					<div className="logo">
						<a href="#0">
							<img src={logo} alt="haku" />
						</a>
					</div>

					<div
						className="header-bar d-lg-none"
						onClick={() => setToggleMenu(!toggleMenu)}
					>
						<img src={toggler} alt="img" />
					</div>
					<div className={`menu-wrapper ${toggleMenu ? "active" : ""}`}>
						{toggleMenu && (
							<div
								className="close-menu"
								onClick={() => setToggleMenu(!toggleMenu)}
							>
								<img src={close_icon} alt="img" />
							</div>
						)}
						{data.menu && (
							<ul className="menu">
								{data.menu.map(({ name, link, target }, i) => (
									<li key={i}>
										<a href={link} target={target ? target : ""}>
											{name}
										</a>
									</li>
								))}
							</ul>
						)}
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
