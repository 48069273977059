import React, { useState } from "react";
import YouTube, { YouTubeProps } from "react-youtube";
import banner__play from "../assets/img/banner-play-thumb.png";
import close_icon from "../assets/img/close_icon.png";
import discord from "../assets/img/discord.png";
import play__icon from "../assets/img/play-icon.png";
import twitter from "../assets/img/twitter.png";
import bannerVideo from "../assets/video/Wip_Foxtopia_01.mp4";

const Banner = () => {
	const [modal, setModal] = useState(false);
	const onPlayerReady: YouTubeProps["onReady"] = (event) => {
		event.target.playVideo();
	};

	const opts: YouTubeProps["opts"] = {
		playerVars: {
			autoplay: 1,
		},
	};

	return (
		<>
			<section className="banner-section">
				<video className="banner-video" autoPlay loop muted>
					<source src={bannerVideo} type="video/mp4" />
				</video>
				<div className="container">
					<div className="banner-content">
						{/* <div className="banner-logo">
							<img src={logo} alt="logo" />
						</div> */}
						<div
							className="banner-play-icon overflow-hidden"
							onClick={() => setModal(!modal)}
						>
							<img src={banner__play} alt="img" className="img" />
							<img src={play__icon} alt="img" className="icon" />
						</div>
						<div className="text">
							Foxtopia is a Free-to-Play and Play to-Earn combat style
							action RPG built on the Solana Blockchain.
						</div>
					</div>
				</div>
				<div className="banner-social">
					<a
						href="http://discord.gg/foxtopia"
						target="_blank"
						rel="noreferrer"
					>
						<img src={discord} alt="" />
					</a>
					<a
						href="https://twitter.com/FoxtopiaNFT"
						target="_blank"
						rel="noreferrer"
					>
						<img src={twitter} alt="" />
					</a>
				</div>
				<a
					className="banner-link-whitepaper"
					href="https://drive.google.com/drive/folders/1ozRAn-nq3Gq674U3_8sUoK08tuHvJDIe"
					target="_blank"
					rel="noreferrer"
				>
					Whitepaper
				</a>
			</section>
			{modal && (
				<div
					className="video-modal-wrapper"
					onClick={() => setModal(false)}
				>
					<div className="close__video">
						<img src={close_icon} alt="" />
					</div>
					<div className="inner" onClick={() => setModal(true)}>
						<YouTube
							videoId="lzbysA5-jW0"
							opts={opts}
							onReady={onPlayerReady}
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default Banner;
