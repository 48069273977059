import img1 from "../img/game-play/1.png";
import img2 from "../img/game-play/2.png";
import img3 from "../img/game-play/3.png";
import img4 from "../img/game-play/4.png";
const game_play = [
	{
		img: img1,
		text: "Embark on quests to unlock unique and rare items to upgrade your character!",
	},
	{
		img: img2,
		text: "Have a taste of the early stages of our game production by playing our mini game demo. ",
	},
	{
		img: img3,
		text: "Experiment with a range of defense and attack skills. ",
	},
	{
		img: img4,
		text: "Hack and slash those enemies away! ",
	},
];
export default game_play;
